@font-face {
  font-family: "NX Gothic";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NEXON_Lv1_Gothic_Low_OTF_Light.woff) format("woff2");
}
@font-face {
  font-family: "NX Gothic";
  font-style: bold;
  font-weight: 700;
  src: url(../fonts/NEXON_Lv1_Gothic_Low_OTF_Bold.woff) format("woff2");
}
