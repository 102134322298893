@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700;900&display=swap');

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none; /* Disable selection/copy in UIWebView */
    box-sizing:border-box;
  }
/* 20210127 alert 떴을때 html에 의해 계속 스크롤 되고있다. 이것을 막아야한다. */
/* html {overflow-y:scroll} */

/*
* {
  font-family: "NX Gothic";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
}

*/



/* * {font-family:'Noto Sans KR','Malgun Gothic'; letter-spacing: -0.5px;} */
* {font-family:'NX Gothic'; letter-spacing: -0.5px;}

/* body {font-size:16px;font-size: .875rem;font-family:'Noto Sans KR','Malgun Gothic', dotum, sans-serif;background:#fff;} */
body {font-size:16px;font-size: .875rem; font-family:'NX Gothic';background:#fff;}

html, body, h1, h2, h3, h4, h5, h6, form, fieldset, img {margin:0;padding:0;border:0}

/* h1, h2, h3, h4, h5, h6 {font-family:'Noto Sans KR','Malgun Gothic', dotum, sans-serif} */
h1, h2, h3, h4, h5, h6 {font-family:'NX Gothic';}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}


div, ul, li, span {box-sizing:border-box;}
ul, dl,dt,dd {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle;font-size:1em}

/* input, button {margin:0;padding:0;font-family:'Noto Sans KR','Malgun Gothic', dotum, sans-serif;font-size:1em; box-sizing:border-box;} */
input, button {margin:0;padding:0;font-family:'NX Gothic';font-size:1em; box-sizing:border-box;}

input[type="submit"] {cursor:pointer}
button {cursor:pointer}

/* textarea, select {font-family:'Noto Sans KR','Malgun Gothic', dotum, sans-serif;font-size:14px;font-size: .875rem;} */
textarea, select {font-family:'NX Gothic';font-size:14px;font-size: .875rem;}

select {margin:0}
p {margin:0;padding:0;word-break:keep-all;}
/*hr {display:none}*/
pre {overflow-x:scroll;font-size:14px;font-size: .875rem;}
a {color:#000;text-decoration:none}
em, i {font-style: normal}
*, :after, :before {}

input[type=text],input[type=password], textarea {outline:none;}

input[type=text]:focus,input[type=password]:focus, textarea:focus,select:focus {}

.placeholdersjs {color:#aaa !important}

/* ios 애플계열 input 스타일 무시 */
input, select, textarea{
    user-select : text;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .primary {
    color: #6463C9;
  }
  .event {
    color: #E79C2B;
  }
