.main_component {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

@media only screen and (max-width: 900px) {
    .main_component {
        display: block;
        width: 100%;
        height: auto;
    }
}

.main_component .nav {
    width: 280px;
    background: #5857B5;
}

.main_component .content {
    width: 1440px;
    padding: 60px 0 60px 280px;
    /*background: rgb(245, 111, 111);*/
}

@media only screen and (max-width: 900px) {
    .main_component .content {
        width: 100%;
        padding: 40px 4%;
    }
}

.main_component .content .complate_component {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
    word-break: keep-all;
}

.complate_component .title {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
}

.complate_component .test {
    align-self: center;

}

.complate_component .comment {
    font-size: 16px;
    text-align: left;
    padding: 2px 0px;
}

.main_component .content .content_component {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 60px 0 30px 0;
}

.main_component .content_component h2 {
    padding-left: 15px;
    padding-bottom: 10px;
}

.main_component .content_component .product_component {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.main_component .result_component {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1.2em;
}

.main_component .result_component table {
    width: 100%;
    border: 3px solid black;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 20px;

    border-spacing: 0px;
    border-collapse: separate;
}

.main_component .result_component table td {
    /* border:1px solid black; */
    padding: 5px 0px 5px 0px;
}

.main_component .result_component table .left {
    text-align: left;
    width: 25%;
    font-weight: bold;
}

.main_component .result_component table .right {
    text-align: right;
    width: 25%;
}

.main_component .result_component table .seperator {
    /* border: 0.5px solid black; */
    background-color: black;
}

.button_class_product {
    padding-left: 30px;
}

.category_button {
    border: 1px solid black;
    width: 300px;
    height: 40px;
    border-radius: 7px;
    background-color: white;
    margin-right: 20px;
    font-size: 1.2em;
}

.button_selected {
    font-weight: bold;
    background-color: #1FAFD2;
    color: #fff;
}

.footer_button {
    text-align: center;
}

.footer_button .confirm {
    border: 1px solid black;
    width: 400px;
    height: 50px;
    border-radius: 7px;
    background-color: #1FAFD2;
    color: #fff;
    margin-right: 20px;
    font-size: 1.2em;
    font-weight: bold;
}

.footer_button .cancel {
    border: 1px solid black;
    width: 400px;
    height: 50px;
    border-radius: 7px;
    background-color: #959595;
    color: #fff;
    margin-right: 20px;
    font-size: 1.2em;
    font-weight: bold;
}

.warning_hour {
    font-weight: bold;
    font-size: 0.8em;
    color: red;
}



/**************************** 견적서 양식 ****************************/
.estimate_component {
    /* A4 size X 3 = (210*3) X (297*3) */
    width: 635px;
    height: 891px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.estimate_component .title {
    font-size: 2.6em;
    font-weight: bold;
}

.estimate_component .sub_title {
    font-size: 1.6em;
    font-weight: bold;
}

.estimate_component .sub_row_component {
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.padding_top {
    padding-top: 40px;
}

.estimate_component .sub_row_component .left {
    text-align: left;
    align-self: center;
}

.estimate_component .sub_row_component .right {
    text-align: right;
}

.estimate_component .sub_row_component .right :first-child {
    padding-bottom: 15px;
}

.estimate_component .price_info {
    padding-top: 20px;
    width: 100%;
    /* border-bottom: 5px solid black; */
}

.estimate_component .price_info table {
    text-align: center;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    padding-bottom: 40px;
}

.estimate_component .price_info table thead th {
    border-top: 5px solid black;
    border-bottom: 1px solid black;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 0px;
}

.estimate_component .price_info table tbody td {
    padding: 10px 0px;
    font-size: 0.9em;
}

.estimate_component .price_info table tbody .total td {
    border-top: 1px solid black;
    text-align: left;
}

.estimate_component .price_info table tbody .total2 td {

    text-align: left;
}

.estimate_component .info {
    align-self: flex-start;
    font-size: 0.9em;
    padding-bottom: 20px;
}

.estimate_component .info div {
    padding: 10px 0px 10px 10px;
}

.estimate_component .seperator {
    width: 100%;
    border-top: 5px solid black;
    padding-bottom: 10px;
}

.estimate_component .sub_row_component2 {
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.padding_top {
    padding-top: 40px;
}

.estimate_component .sub_row_component2 .left {
    text-align: left;
    align-self: center;
}

.estimate_component .sub_row_component2 .right {
    text-align: right;
    align-self: center;
}

.estimate_component .sub_row_component2 .left :first-child {
    padding-bottom: 15px;
}

.estimate_component .sub_row_component2 .right :first-child {
    padding-bottom: 15px;
}

.estimate_component .sub_row_component2 .dojang img {
    width: 100px;
}

/**************************** 상품 노드(월간모시러) ****************************/
.product_node_mm {
    text-align: center;
    letter-spacing: -0.5px;
    line-height: normal;
}

.selected_mm {
    border: 3px solid #3c3aa2;
}

.product_node_mm span {
    display: block;
}

/* ??시간 상품 영역 */
.product_node_mm .pd_header {
    color: #fff;
    padding: 0 10px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
}

.product_node_mm .pd_body {
    padding: 16px;
}

/* 파트너를 월 ??시간동안 영역 */
.product_node_mm .pd_content {
    padding-bottom: 8px;
}

.product_node_mm .pd_content3 {
    padding: 8px 0;
}

.product_node_mm .pd_content3 strong {
    font-size: 14px;
}

.product_node_mm .pd_content .pd_oneDayHour {
    margin-top: 4px;
}

.product_node_mm .pd_content_custom {
    padding: 10px 0px 10px 0px;
}

/* 시간당 단가 영역 */
.product_node_mm .pd_content3 {
    border-top: 1px solid #DCDBFC;
    border-bottom: 1px solid #DCDBFC;
    text-align: left;
}

.product_node_mm .pd_content3 .price_tbl {
    height: 45px;
}

.product_node_mm .pd_content3 .price_tbl2 {
    height: 45px;
    line-height: 45px;
}

.product_node_mm .pd_content3 .price_tbl table {
    margin: 0 auto;
}

.product_node_mm .price_container {
    position: relative;
    text-align: right;
    line-height: normal;
}

.product_node_mm .price_container .delete_price {
    color: #cecece;
    font-weight: bold;
    font-size: 16px;
}

.product_node_mm .price_container .discount_percent {
    color: #FF8064;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 15px;
}

.product_node_mm .price_container .result_price {
    color: #000;
    font-size: 24px;
    font-weight: bold;
}

/* 금액 표시 */
.product_node_mm .pd_footer {
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 32px;
    padding: 4px 0 20px 0px;
}

.product_node_mm .pd_footer span {
    display: inline-block;
    vertical-align: bottom;
    font-size: 32px;
    color: #6463C9;
}

.pd_checked {
    text-align: center;
    margin-top: 16px;
}

@media only screen and (max-width: 600px) {
    .pd_checked {
        display: none;
    }
}

.pd_checked img {
    width: 32px;
}



/**************************** 상품 노드(연간모시러) ****************************/
.product_node_ym {
    text-align: center;
    width: 150px;
    background-color: white;
    margin: 0 10px 0 10px;
    border-radius: 10px;
    letter-spacing: -0.5px;
    border: 1px solid black;
}

.selected_ym {
    border: 3px solid #ae9147;
}

.product_node_ym span {
    display: block;
}

/* ??시간 상품 영역 */
.product_node_ym .pd_header {
    padding: 10px 0px;
    font-size: 1.4em;
    color: #d4b157;
    font-weight: bold;
}

/* 파트너를 월 ??시간동안 영역 */
.product_node_ym .pd_content {
    padding: 20px 0px;
}

/* 1일 최소 8시간 이상 영역 */
.product_node_ym .pd_content2 {
    padding: 10px 0px 20px 0px;
}

/* 시간당 단가 영역 */
.product_node_ym .pd_content3 {
    padding: 20px 0px;
}

.product_node_ym .pd_content3 .price_tbl {
    height: 45px;
}

.product_node_ym .pd_content3 .price_tbl2 {
    height: 45px;
    line-height: 45px;
}

.product_node_ym .pd_content3 .price_tbl table {
    margin: 0 auto;
}

.delete_text_price {
    text-decoration: line-through;
    text-decoration-color: red;
    color: #a2a2a2;
    font-weight: bold;
}

.discount_percent {
    color: red;
    font-size: 0.8em;
    font-weight: bold;
}

.result_price {
    font-size: 1.4em;
    font-weight: bold;
}

/* 금액 표시 */
.product_node_ym .pd_footer {
    padding: 15px 0px;
    font-weight: bold;
    font-size: 1.4em;
    color: red;
}

/* 구분선 */
.hr_custom {
    width: 110px;
    margin: 0 auto;
    border: 1px solid rgb(63, 63, 63);
}



/**************************** 견적서 로딩 ****************************/
.loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 3em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.loader,
.loader:before,
.loader:after {
    animation: 1s infinite ease-in-out;
}

.loader:before,
.loader:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.loader-9,
.loader-10 {
    background-color: white;
    animation: loader9 0.4s infinite linear;
}

.loader-10 {
    animation: loader10 60s infinite ease-in-out;
}

.loader-9:before,
.loader-10:before {
    content: '';
    width: 80%;
    height: 80%;
    background-color: white;
    top: 10%;
    left: 10%;
    box-shadow: 5px -3px 0 rgba(255, 100, 100, 0.7), 5px 5px 0 rgba(100, 255, 100, 0.7), -3px 5px 0 rgba(100, 100, 255, 0.7), -5px -5px 0 rgba(240, 240, 120, 0.7);
}

.loader-9:after,
.loader-10:after {
    content: '';
    border: 3px solid white;
    z-index: 2;
    top: -3px;
    left: -3px;
}

@keyframes loader9 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader10 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360000deg);
    }
}



/**************************** 소개서 ****************************/
.introduce_component {
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 0px;
}

/* 최상위 코드 레벨 */
@media screen and (max-width: 999px) {
    .introduce_component img {
        max-width: 100%;
        max-height: auto;
    }

    .introduce_component2 button {
        display: inline-block;
        background-color: #4B4B4B;
        height: 4.5em;
        border: 2px solid #323232;
        width: 60%;
        border-radius: 20px;
    }

    .introduce_component3 button {
        display: inline-block;
        background-color: #4B4B4B;
        height: 4.5em;
        border: 2px solid #323232;
        width: 60%;
        border-radius: 20px;
    }

    .input-text-custom {
        font-size: 1em;
        padding: 5px 0;
    }

    .input-text-custom input {
        width: 60%;
        height: 40px;
        border: 1px solid black;
        border-radius: 5px;
        padding-left: 10px;
    }

}

@media screen and (min-width: 1000px) {
    .introduce_component img {
        max-width: 1000px;
        max-height: auto;
    }

    .introduce_component2 button {
        display: inline-block;
        background-color: #4B4B4B;
        height: 4.5em;
        border: 2px solid #323232;
        width: 400px;
        border-radius: 20px;
    }

    .introduce_component3 button {
        display: inline-block;
        background-color: #4B4B4B;
        height: 4.5em;
        border: 2px solid #323232;
        width: 400px;
        border-radius: 20px;
    }

    .input-text-custom {
        font-size: 1em;
        padding: 5px 0;
    }

    .input-text-custom input {
        width: 500px;
        height: 40px;
        border: 1px solid black;
        border-radius: 5px;
        padding-left: 10px;
    }

}

.final {
    margin-bottom: 130px;
}

.introduce_component2 {
    width: 100%;
    text-align: center;
    padding-top: 25px;
}

/* .introduce_component2 button {display:inline-block; background-color: #4B4B4B; height:5em; border: 2px solid #323232; width:70%; border-radius: 20px; } */
.introduce_component2 button span {
    font-size: 1.8em;
    color: white;
    font-weight: bold;
}

.introduce_component3 {
    position: fixed;
    bottom: 30px;
    width: 100%;
    text-align: center;
    padding-top: 25px;
}

/* .introduce_component2 button {display:inline-block; background-color: #4B4B4B; height:5em; border: 2px solid #323232; width:70%; border-radius: 20px; } */
.introduce_component3 button span {
    font-size: 1.8em;
    color: white;
    font-weight: bold;
}

/* 20211216 rise 채널톡->카톡채널 변경으로 인한 css추가 */
.scroll-to-top {
    position: fixed;
    bottom: 1.8375rem;
    right: .8125rem;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    z-index: 9;
}

.scroll-to-top>button {
    border: none;
}

.scroll-to-top>button:focus {
    border: none;
    outline: none;
}

.btn-kakao-qna {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #454545 url("../img/ico_kakao_qna.png");
    background-repeat: no-repeat;
    background-size: 59px;
    background-position: 50% 50%;
    transition: all 0.23s ease-in-out, box-shadow 1.5s linear;
    box-shadow: 0px 10px 15px 0 rgb(0 0 0 / 25%);
}

.subtitle {
    font-size: 2em;
    padding-bottom: 30px;
    font-weight: bold;
}

.fadein_cn {
    display: block !important;
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s;
    /* Firefox */
    -webkit-animation: fadein 0.5s;
    /* Safari and Chrome */
    -o-animation: fadein 0.5s;
    /* Opera */
    animation-fill-mode: forwards;
}

.fadeout_cn {
    display: none !important;
    animation: fadeout 0.5s;
    -moz-animation: fadeout 0.5s;
    /* Firefox */
    -webkit-animation: fadeout 0.5s;
    /* Safari and Chrome */
    -o-animation: fadeout 0.5s;
    /* Opera */
    animation-fill-mode: forwards;
}