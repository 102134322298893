.floatlabel_component {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.floatlabel_component label {
    position: relative;
    overflow: hidden;
}

.floatlabel_component.type-datepicker label {
    overflow: visible;
}

.validate {
    /* border-bottom:3px solid red !important; */
    border: 1px solid #FF6464 !important;
}

.floatlabel_component input {
    width: 100%;
    height: 62px;
    padding: 18px 10px 10px;
    border: none;
    outline: none;
    font-size: 16px;
    border: 1px solid #ECEEF2;
    border-radius: 12px;
    transition: padding 0.2s;
    background: transparent;
    transition: border 0.2s;
}

.floatlabel_component input[type=file] {
    padding-right: 88px;
    line-height: 30px;
}

.floatlabel_component input[type=file]:focus {
    border: 1px solid #ECEEF2;
    padding-left: 10px;
}

.floatlabel_component input:focus {
    border: 1px solid #6463C9;
}

.floatlabel_component input.calendar {
    background: url("../img/icon_calendar.png") no-repeat 10px 24px;
    background-size: 20px;
    padding-left: 36px;
}

.floatlabel_component select {
    width: 100%;
    height: 62px;
    padding: 18px 10px 10px;
    font-size: 16px;
    border: 1px solid #ECEEF2;
    border-radius: 12px;
    transition: padding 0.2s;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    position: relative;
    background: url("../img/icon_select.png") no-repeat right 10px center;
    background-size: 24px;
    transition: border 0.2s;
}

.floatlabel_component select:focus {
    border: 1px solid #706ed1;
}

.floatlabel_component select:focus+span {
    color: #706ed1;
}

.floatlabel_component input:focus+span {
    color: #706ed1;
}

.floatlabel_component input::placeholder {
    opacity: 0.4;
}

.floatlabelselect_input {
    margin-top: 0 !important;
    font-size: 1em !important;
}

.floatlabelselect_input::placeholder {
    opacity: 1 !important;
}

.floatlabel_component span {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-top: 6px;
    color: #2d2d2d;
    font-size: 12px;
    transform: translateY(0px);
    opacity: 1;
}

.floatlabel_component select+span {
    color: #2d2d2d;
    font-size: 12px;
    transform: translateY(0px);
    opacity: 1;
}

.floatlabel_component.type-datepicker .react-datepicker {
    border-radius: 12px;
    border: 1px solid #ECEEF2;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.floatlabel_component.type-datepicker .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}

.floatlabel_component.type-datepicker .react-datepicker__header button {
    background: #fff;
}

.floatlabel_component.type-datepicker .react-datepicker__month {
    font-size: 14px;
}

.floatlabel_component.type-datepicker .react-datepicker__triangle {
    display: none;
}

.floatlabel_component.type-datepicker .react-datepicker__day:hover,
.floatlabel_component.type-datepicker .react-datepicker__month-text:hover,
.floatlabel_component.type-datepicker .react-datepicker__quarter-text:hover,
.floatlabel_component.type-datepicker .react-datepicker__year-text:hover {
    border-radius: 100%;
}

.floatlabel_component.type-datepicker .react-datepicker__day--selected,
.floatlabel_component.type-datepicker .react-datepicker__day--selected:hover,
.floatlabel_component.type-datepicker .react-datepicker__day--keyboard-selected,
.floatlabel_component.type-datepicker .react-datepicker__day--keyboard-selected:hover {
    border-radius: 100%;
    background-color: #706ed1;
}